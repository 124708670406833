<template>
  <div class="oldBrand-add">
    <GlobalChunk icon="add" title="余额上账" :padding="[15, 0, 95, 10]">
      <el-row>
        <el-col :span="10">
          <GlobalForm ref="ruleform" :uploadLength="3" :init-data="initData" :form-rules="formRules"
            :form-item-list="formItemList" @handleConfirm="handleConfirm" confirmBtnName="确认提交">
            <el-form-item class="el_from" label="" prop="balance" slot="balance">
              <p class="nowPrice">当前平台账户余额： <span style="color: #F83232;">{{ compnyList.balance }}元</span></p>
            </el-form-item>
            <el-form-item class="el_from" label="上账金额" prop="amountPrice" slot="amountPrice">
              <el-input placeholder="请输入上账金额" @input="limitInput($event, 'amountPrice')"
                v-model.trim="initData.amountPrice"></el-input>
              <span v-if="NumMoney" style="color:#F83232;font-weight: bold;">{{ NumMoney }}</span>
            </el-form-item>
            <el-form-item class="el_from" label="上账原因" prop="cause" slot="cause">
              <el-input maxlength="200" type="textarea" placeholder="请输入原因说明（限200字）" v-model="initData.cause"></el-input>
            </el-form-item>
            <el-button slot="btnSlot" @click="FromBack">返回</el-button>
          </GlobalForm>
        </el-col>
      </el-row>
    </GlobalChunk>
    <!-- 动态口令弹出框 -->
    <el-dialog :title='commandTitle' :visible.sync="commandShow" :close-on-click-modal="false" width="410px"
      @closed="commandClosed">
      <div class="sure-tip" v-if="accountType == 'add'"><span>请仔细核对上账金额与收款金额是否一致</span></div>
      <div class="sure-tip" v-else><span>请仔细核对上账金额与付款金额是否一致</span></div>
      <div class="amount-price"><span>上账金额：</span><span>{{ params.amount
      }}</span></div>
      <div class="command">
        <div style="width: max-content;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌验证码"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="submit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import { formValidateMobileLandline } from "@/utils/form-validate";
import _api from "@/utils/request";
import moment from "moment";
export default {
  name: "oldBrand-add",
  data() {
    return {
      storeoption: [],
      butLoading: false,
      initData: {
        amountPrice: "",
        cause: "",
      },
      commandTitle: "",
      commandShow: false,
      accountType: "",
      command: "",
      shanpsSelectList: [],
      staffList: [],
      params: {},
      NumMoney: "",
      formItemList: [
        { slotName: 'balance' },
        { slotName: 'amountPrice' },
        { slotName: "cause" },
      ],
      compnyList: {
        balance: 0,
        contactName: "",
        contactPhone: "",
      },
      formRules: {
        amountPrice: [
          {
            required: true,
            trigger: "blur",
            message: "输入上账金额",
          },
        ],
        cause: [
          {
            required: true,
            trigger: "blur",
            message: "输入上账原因（200字内）",
          },
        ],
      },
    };
  },
  created() {
    console.log(this.changeNumMoneyToChinese(2001.22));
    this.handlebalance()
  },
  methods: {
    // 查询余额
    handlebalance() {
      let timearr = [new Date(moment().subtract(30, 'day').startOf('day').format("YYYY-MM-DD") + " " + '00:00:00'),
      new Date(moment().endOf('day').format("YYYY-MM-DD") + ' ' + "23:59:59")]
      let SeachParams = {
        startTime: moment(timearr[0]).format("x"),
        endTime: moment(timearr[1]).format("x"),
        pageNum: 1,
        pageSize: 10,
      }
      _api.getaccountPlatform(SeachParams).then(res => {
        this.compnyList.balance = res.data.platformBalance || 0
      });
    },
    handleConfirm(data) {
      console.log(data);
      this.params = {
        amount: data.amountPrice,
        remark: data.cause
      };
      this.commandTitle = '上账确认'
      this.commandShow = true
    },
    FromBack() {
      this.$router.go(-1);
    },
    commandClosed() {
      this.commandShow = false
      this.butLoading = false
      this.command = ""
    },
    submit() {
      if (!this.command) {
        return this.$message.error("请输入动态口令");
      }
      this.params.command = this.command
      this.params.amount = Number(this.params.amount)
      this.butLoading = true
      _api.PlatformaddBalance(this.params).then((res) => {
        if (res.code === 1) {
          this.$router.push({ path: "/payment/PlatformBilling" });
          this.$message({
            message: "上账成功",
            type: "success",
          });
        } else {
          this.$message({
            message: "上账失败",
            type: "error",
          });
        }
        this.butLoading = false
      }).catch(err => {
        this.butLoading = false
      });
    },
    limitInput(value, name) {
      console.log(value);
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.initData[name] = "";
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      let valJoin = value.join("");
      this.initData[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      this.NumMoney = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] ? this.changeNumMoneyToChinese(valJoin.match(/^\d*(\.?\d{0,2})/g)[0]) : ""
    },
    changeNumMoneyToChinese(money) {
      var cnNums = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"); //汉字的数字
      var cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
      var cnIntUnits = new Array("", "万", "亿", "兆"); //对应整数部分扩展单位
      var cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
      var cnInteger = "整"; //整数金额时后面跟的字符
      var cnIntLast = "元"; //整型完以后的单位
      var maxNum = 999999999999999.9999; //最大处理的数字
      var IntegerNum; //金额整数部分
      var DecimalNum; //金额小数部分
      var ChineseStr = ""; //输出的中文金额字符串
      var parts; //分离金额后用的数组，预定义    
      var Symbol = "";//正负值标记
      if (money == "") {
        return "";
      }

      money = parseFloat(money);
      if (money >= maxNum) {
        alert('超出最大处理数字');
        return "";
      }
      if (money == 0) {
        ChineseStr = cnNums[0] + cnIntLast + cnInteger;
        return ChineseStr;
      }
      if (money < 0) {
        money = -money;
        Symbol = "负 ";
      }
      money = money.toString(); //转换为字符串
      if (money.indexOf(".") == -1) {
        IntegerNum = money;
        DecimalNum = '';
      } else {
        parts = money.split(".");
        IntegerNum = parts[0];
        DecimalNum = parts[1].substr(0, 4);
      }
      if (parseInt(IntegerNum, 10) > 0) { //获取整型部分转换
        var zeroCount = 0;
        var IntLen = IntegerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = IntegerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          }
          else {
            if (zeroCount > 0) {
              ChineseStr += cnNums[0];
            }
            zeroCount = 0; //归零
            ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            ChineseStr += cnIntUnits[q];
          }
        }
        ChineseStr += cnIntLast;
        //整型部分处理完毕
      }
      if (DecimalNum != '') { //小数部分
        var decLen = DecimalNum.length;
        for (let i = 0; i < decLen; i++) {
          let n = DecimalNum.substr(i, 1);
          if (n != '0') {
            ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (ChineseStr == '') {
        ChineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (DecimalNum == '') {
        ChineseStr += cnInteger;
      }
      ChineseStr = Symbol + ChineseStr;

      return ChineseStr;
    },
  },
};
</script>
  
<style scoped lang="scss" ref="stylesheet/scss">
.oldBrand-add {
  .el_from {
    margin-left: -120px;
    margin-bottom: 0;
  }

  /deep/.el-select {
    width: 100% !important;
  }

  .command {
    display: flex;
    align-items: center;

    /deep/.el-input {
      width: 300px;
    }
  }

  .nowPrice {
    margin-left: 50px;
  }

  .amount-price {
    color: #F59A23;
    font-size: 14px;
    margin: 15px 0;
  }

  .sure-tip {
    color: #0981FF;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
  